@import ../../../styles/helpers

.hero
    position: relative
    display: flex
    align-items: center
    min-height: 780px
    padding: 34px 0 130px
    +x
        min-height: 800px
    +d
        min-height: 624px
    +m
        min-height: auto
        padding: 64px 0 152px

.wrap
    position: relative
    z-index: 3
    max-width: 500px
    +t
        max-width: 340px
    +m
        max-width: 100%
        margin-bottom: 80px

.title
    margin-bottom: 20px
    //added here to fix spacing for title
    line-height: 1.1

.text
    margin-bottom: 40px
    color: $neutrals4

.btns
    display: flex
    +m
        display: block

.button
    +m
        width: 100%
    &:not(:last-child)
        margin-right: 16px
        +m
            margin: 0 0 16px
    
.scroll
    position: absolute
    bottom: 96px
    +d
        bottom: 48px
    +m
        bottom: 64px

.gallery
    position: absolute
    top: 45%
    right: calc(50% - 565px)
    width: 512px
    transform: translateY(-58%)
    pointer-events: none
    +x
        right: calc(50% - 525px)
        width: 450px
    +d
        right: calc(50% - 465px)
        width: 450px
    +t
        right: calc(50% - 360px)
        width: 450px
    +m
        position: relative
        top: auto
        right: auto
        left: -16px
        margin: 0 auto
        transform: translate(0,0)
        pointer-events: all

.preview
    &:first-child
        position: relative
        z-index: 2
    &:not(:first-child)
        position: absolute
    img
        width: 100%