@import ../../styles/helpers

.section
    text-align: center

.stage
    margin-bottom: 16px

.title
    max-width: 850px
    margin: 0 auto 50px
    +t
        max-width: 550px

.text
    max-width: 850px
    margin: 0 auto 48px
    color: $neutrals4

