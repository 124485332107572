[class^="button"]
  display: inline-flex
  justify-content: center
  align-items: center
  height: 48px
  padding: 0 24px
  background: $yellow
  border-radius: 24px
  +button-1
  text-align: center
  color: $neutrals2
  transition: all .2s
  &:hover
    background: darken($yellow, 10)
  &.disabled
    opacity: .5
  svg
    fill: $neutrals2
  &:not([class^="button-circle"])
    svg
      &:last-child
        margin-left: 15px

.button-stroke
  background: none
  box-shadow: 0 0 0 2px $neutrals6 inset
  color: $neutrals2
  svg
    fill: $neutrals2
    transition: fill .2s
  &:hover
    box-shadow: 0 0 0 2px $neutrals2 inset
    background: $neutrals2
    color: $neutrals8
    svg
      fill: $neutrals8
  +dark-common
    box-shadow: 0 0 0 2px $neutrals4 inset
    color: $neutrals8
    svg
      fill: $neutrals8
    &:hover
      box-shadow: 0 0 0 2px $neutrals8 inset
      background: none
  
.button-stroke-special
  position: relative
  color: #FFFFFF  // White text color
  border: 2px solid transparent
  border-radius: 24px
  cursor: pointer
  background: #150011
  background-clip: padding-box
  box-shadow: 0 0 8px 0 rgba(#7928CA, 0.5), 0 0 8px 0 rgba(#FF0080, 0.5)
  &:hover
      background: #150011
      top: -2px
      right: -2px
      bottom: -2px
      left: -2px
  &:before
    content: ''
    position: absolute
    top: -2px
    right: -2px
    bottom: -2px
    left: -2px
    z-index: -1
    border-radius: inherit
    background: linear-gradient(to right, #7928CA, #FF0080) // Adjusted gradient colors to match the image
    opacity: 0.5
    
  svg
    fill: #FFFFFF // Assuming the SVG should also be white
    transition: fill .2s
  +dark-common

.button-indicator
  background: none
  box-shadow: 0 0 0 2px $neutrals6 inset
  color: $neutrals2
  svg
    fill: $yellow
    transition: fill .2s
  &:hover
    box-shadow: 0 0 0 2px $yellow inset
    background: $yellow
    color: $neutrals8
    svg
      fill: $neutrals8
  +dark-common
    box-shadow: 0 0 0 2px $yellow inset
    color: $yellow
    svg
      fill: $neutrals8
    &:hover
      box-shadow: 0 0 0 2px $neutrals8 inset
      color: $neutrals8
      background: none
      cursor: default


.button-small
  height: 40px
  width: 100px
  border-radius: 20px
  margin: 8px
  padding: 16px
  font-size: 14px

.button-circle-stroke
  flex: 0 0 48px
  width: 48px
  height: 48px
  padding: 0
  border-radius: 50%
  box-shadow: 0 0 0 2px $neutrals6 inset
  background: transparent
  transition: border-color .2s
  svg
      fill: $neutrals4
      transition: fill .2s
  &:hover
      background: $neutrals2
      box-shadow: 0 0 0 2px $neutrals2 inset
      svg
          fill: $neutrals8
  +dark-common
    box-shadow: 0 0 0 2px $neutrals3 inset
    &:hover
      background: $neutrals3



