@import ../../styles/helpers

.form
    position: relative

.input
    width: 100%
    height: 48px
    padding: 0 48px 0 14px
    border-radius: 24px
    background: none
    border: 2px solid $neutrals6
    +poppins
    +caption-1
    color: $neutrals2
    transition: border-color .2s
    +dark
        border-color: $neutrals3
        color: $neutrals8
    &:focus
        border-color: $neutrals4
    +placeholder
        color: $neutrals4

.btn
    position: absolute
    top: 8px
    right: 8px
    bottom: 8px
    width: 32px
    height: 32px
    border-radius: 50%
    background: $yellow
    transition: background .2s
    svg
        fill: $neutrals8
    &:hover
        background: darken($yellow, 10)