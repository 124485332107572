@import ../../styles/helpers

.negMargin
    display: flex,
    flex-direction: row
    flex-wrap: wrap
    align-content: flex-start
    max-width: 80%
    margin: -100px auto 64px
    //changed alignment to center
    justify-content: center
    //added gap between items
    //gap: 24px
    +m
        max-width: 90%