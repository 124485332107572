@import ../../styles/helpers

.container
    margin-top: 100px
    display: flex
    flex-direction: column
    align-items: center

.title
    margin-bottom: 20px
    text-align: center
    font-weight: 600


.companies
    margin-top: 80px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    gap: 12px
    max-width: 90%
    align-content: flex-start
    +m
        display: flex
        flex-direction: row
        flex-wrap: wrap
        max-width: 100%
        margin-top: 40px
        justify-content: center
        align-content: flex-start

.text
    text-align: center
    color: $neutrals4


.logo
    width: 15%
    margin: 24px 18px
    display: flex
    justify-content: center
    +d
        margin: 24px 12px
    +m
        flex: 0 0 140px
        margin: 0
    img
        max-height: 32px
        margin: 24px 8px
        width: 120px
        object-fit: contain